



































import { Component, Vue, Prop } from 'vue-property-decorator';
import {ISpecShareMessageRequest} from "@/entities/specs/spec-share-message-entity";
import { help } from '@/lang/help/spec-submission';

@Component
export default class extends Vue {
  @Prop({required: true}) private message!:ISpecShareMessageRequest;
  private visible:boolean = true;

  private readonly help = help;
}
